/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { Card, Form, Modal, Tooltip } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useNavigate } from "react-router-dom";
import CalendarIcon from "../../Asset/Images/Header/calendar-icon.svg";
import DownArrow from "../../Asset/Images/Header/down-arrow.svg";
import GmailIcon from "../../Asset/Images/Header/gmail-icon.svg";
import PlusCircle from "../../Asset/Images/Header/plus-circle.svg";
import ToList from "../../Asset/Images/Icons/to-list.svg";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllClients,
  getGlobalHotels,
  hotelsUpdateData,
  selectedPropertyID,
} from "../../Redux/actions";
import HotelSearch from "../../Views/Common/HotelSearch/Index";
import HeaderCommonSearch from "../../Views/Common/Search/Index";

import AddAccountModal from "../../Model/MenuTopModals/AddAccountModal";
import AddActivityModal from "../../Model/MenuTopModals/AddActivityModal";
import AddContactModal from "../../Model/MenuTopModals/AddContactModal";
import AddLeadModal from "../../Model/MenuTopModals/AddLeadModal";
import SendEmailToClients from "../../Model/SendEmailToClients";
import {
  CommonService,
  Mainlogout,
  ToasterError,
  ToasterSuccess,
  getDateInFormat,
} from "../../Services/CommonServices";
import {
  GetHotelsListData,
  GetTodoListData,
  GetTodoMarkAsComplete,
  GetTodoPastActivity,
} from "../../Services/Setup&Config/HotelService";
import PageLoader from "../Loader/PageLoader";
import PriorActivities from "../../Views/Activity/DetailView/PriorActivities";
import { getSystemSettingData } from "../../Services/Setup&Config/GeneralSettingService";
import { Cookies } from "react-cookie-consent";
import MailInbox from "../../Model/MailInbox";
import { GetNotificationList, ReadNotification } from "../../Services/NotificationServices";
import moment from "moment";
import { getSingleUserSelectedData } from "../../Services/Setup&Config/UserSetupService";
import AddHelpDesk from "../../Model/AddHelpDesk";
import Swal from "sweetalert2";
import DefaultUserlogo from "../../Asset/Images/AvtarImages/Userlogo.png";
import { VerifyClientLogin } from "../../Services/LoginServices";

const CryptoJS: any = require("crypto-js");
const cryptoKey = "SqT_InntelligentCRM_kamlesh_393987";

function MyVerticallyCenteredModalTodo(props) {
  let page_rights_Account = CommonService.getPageRight("Account");
  let page_rights_LNR = CommonService.getPageRight("LNR");
  let page_rights_RFP = CommonService.getPageRight("RFP");
  let page_rights_Group = CommonService.getPageRight("Group");
  let page_rights_MeetingAndCatering = CommonService.getPageRight("MeetingAndCatering");
  let page_rights_Contacts = CommonService.getPageRight("Contacts");
  let page_rights_Lead = CommonService.getPageRight("Lead");

  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dataSelector: any = useSelector((state: any) => state.session);
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const Token = dataSelector.access_Token;
  const SelectedPropertyIDs: any = useSelector((state: any) => state.dashboard);
  const [CurrentData, setCurrentData]: any = useState(false);
  const [MainTodoResult, setMainTodoResult]: any = useState([]);
  const [MainTodoResultRow, setMainTodoResultRow]: any = useState([]);
  const [PastActivities, setPastActivities]: any = useState([]);
  const [PastActivitiesRow, setPastActivitiesRow]: any = useState([]);

  useEffect(() => {
    if (SelectedPropertyIDs.SelectedPropertyID.length > 0) {
      getTodos();
    }
  }, [SelectedPropertyIDs.SelectedPropertyID]);

  async function getTodos() {
    setCurrentData(false);
    let input: any = {
      userID: userId,
      propertyIDs: SelectedPropertyIDs.SelectedPropertyID,
    };
    setPastActivities([]);
    setPastActivitiesRow([]);
    setLoading(true);
    let res: any = await GetTodoListData(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data) {
          setMainTodoResult(res.data.data.dataList1);
          setMainTodoResultRow(res.data.data.dataList2);
        }
      }
    }
    setLoading(false);
  }

  const [isDisable, setisDisable]: any = useState([]);

  async function handleCheck(activity_ID) {
    setisDisable([...isDisable, activity_ID]);

    let input: any = {
      keyID: activity_ID,
    };
    let res: any = await GetTodoMarkAsComplete(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        let Message = "Status Updated SuccessFully";
        ToasterSuccess(Message);
      } else {
        ToasterError("Something went wrong.");
      }
    } else {
      ToasterError(res.message);
    }
  }

  async function handlePastActivities() {
    setCurrentData(true);
    let input: any = {
      userID: userId,
      propertyIDs: SelectedPropertyIDs.SelectedPropertyID,
    };
    setMainTodoResult([]);
    setMainTodoResultRow([]);
    setLoading(true);
    let res: any = await GetTodoPastActivity(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setPastActivities(res.data.data.dataList1);
          setPastActivitiesRow(res.data.data.dataList2);
        }
      } else {
        ToasterError("Something went wrong.");
      }
    } else {
      ToasterError(res.message);
    }
    setLoading(false);
  }

  function handleClose() {
    if (window.location.pathname === "/activityList") {
      <PriorActivities />;
      props.onHide(true);
    } else {
      props.onHide(true);
    }
  }

  function handleNavigate(itm) {
    if (itm.AssociationType_Term.toLowerCase() == "rfp oppo") {
      if (page_rights_RFP.is_view) {
        navigate("/Opportunity/RFP/details", { state: { OppoID: itm.AssociationID } });
        props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "lnr oppo") {
      if (page_rights_LNR.is_view) {
        navigate("/Opportunity/LNR/details", { state: { LNROppoID: itm.AssociationID } });
        props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "group oppo") {
      if (page_rights_Group.is_view) {
        navigate("/Opportunity/Group/details", { state: { GroupOppoID: itm.AssociationID } });
        props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "mac oppo") {
      if (page_rights_MeetingAndCatering.is_view) {
        navigate("/Opportunity/MeetingAndCatering/details", {
          state: { MACOppoID: itm.AssociationID },
        });
        props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "lead") {
      if (page_rights_Lead.is_view) {
        navigate("/suspect/details", {
          state: { leadID: itm.AssociationID, leadName: itm.AccountName },
        });
        props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "contact") {
      if (page_rights_Contacts.is_view) {
        navigate("/contact/details", {
          state: { ContactID: itm.AssociationID, ContactName: itm.AccountName },
        });
        props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "account") {
      if (page_rights_Account.is_view) {
        navigate("/accounts-view", {
          state: { AccountID: itm.AssociationID, AccountName: itm.AccountName },
        });
        props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "accounts") {
      if (page_rights_Account.is_view) {
        navigate("/accounts-view", {
          state: { AccountID: itm.AssociationID, AccountName: itm.AccountName },
        });
        props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
  }

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter "
      dialogClassName="modal-todo-list"
      className="todo-list-popup right"
      backdropClassName="backdrop-modal"
      scrollable={true}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="to-do-heading">
          To do list
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div style={{ textAlign: "center", color: "blue", cursor: "pointer", paddingTop: "15px" }}>
          {CurrentData === true ? (
            <div onClick={getTodos} tabIndex={-1} onKeyDown={getTodos} role={"button"}>
              {" "}
              Click here to see Current Week Activities &nbsp;{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </div>
          ) : (
            <div
              onClick={handlePastActivities}
              tabIndex={-1}
              onKeyDown={handlePastActivities}
              role={"button"}
            >
              {" "}
              Click here to see Past Open Activities &nbsp;{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </div>
          )}
        </div>

        {!PastActivities.length ? (
          !loading ? (
            <div className="to-do-dropdown">
              <div className="to-do-content">
                <div className="day-col">
                  {MainTodoResult.map((item) => (
                    <>
                      <br />
                      <div className="day-heading">
                        {
                          item.Date === undefined && item.Date === null && item.Date === ""
                            ? "-"
                            : getDateInFormat(item.Date)
                          // item.Date.split("T")[0]}
                        }
                      </div>

                      {MainTodoResultRow.map(
                        (itm, index) =>
                          itm.Date === item.Date && (
                            <>
                              <Form className="to-do-checkbox-content mt-2">
                                <div className="to-do-checkbox-fields">
                                  <div className="to-do-checkbox-filed">
                                    <div className="form-check">
                                      <input
                                        name="group1"
                                        type="checkbox"
                                        // id="inline-checkbox-100"
                                        // id={`inline-checkbox-100`}
                                        // id={itm.ActivitySubject + index}
                                        className="form-check-input"
                                        disabled={isDisable.includes(itm.ActivityID) ? true : false}
                                        onChange={() => handleCheck(itm.ActivityID)}
                                      />
                                      <label
                                        // htmlFor="inline-checkbox-100"
                                        htmlFor={itm.ActivitySubject + index}
                                        className="form-check-label"
                                      >
                                        <span
                                          style={{ color: "blue" }}
                                          // className="form-check-label"
                                          onClick={() => handleNavigate(itm)}
                                        >
                                          {itm.ActivitySubject === undefined &&
                                          itm.ActivitySubject === null &&
                                          itm.ActivitySubject === ""
                                            ? "-"
                                            : itm.ActivitySubject}
                                        </span>
                                        <span className="checkbox-time">
                                          {" "}
                                          {itm.ActivityTime === undefined &&
                                          itm.ActivityTime === null &&
                                          itm.ActivityTime === ""
                                            ? "-"
                                            : itm.ActivityTime}{" "}
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </Form>
                            </>
                          )
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <PageLoader />
          )
        ) : !loading ? (
          <div className="to-do-dropdown">
            <div className="to-do-content">
              <div className="day-col">
                {PastActivities.length > 0 &&
                  PastActivities.map((item) => (
                    <>
                      <br />
                      <div className="day-heading">
                        {
                          item.Date === undefined && item.Date === null && item.Date === ""
                            ? "-"
                            : getDateInFormat(item.Date) //.split("T")[0]
                        }
                      </div>
                      {PastActivitiesRow.map(
                        (itm, index) =>
                          itm.Date === item.Date && (
                            <>
                              <Form className="to-do-checkbox-content mt-2">
                                <div className="to-do-checkbox-fields">
                                  <div className="to-do-checkbox-filed">
                                    <div className="form-check">
                                      <input
                                        name="group1"
                                        type="checkbox"
                                        // id="inline-checkbox-100"
                                        // id={itm.ActivitySubject + index}
                                        className="form-check-input"
                                        disabled={isDisable.includes(itm.ActivityID) ? true : false}
                                        onChange={() => handleCheck(itm.ActivityID)}
                                      />
                                      <label
                                        // htmlFor="inline-checkbox-100"
                                        htmlFor={itm.ActivitySubject + index}
                                        className="form-check-label"
                                      >
                                        <span
                                          style={{ color: "blue" }}
                                          // className="form-check-label"
                                          onClick={() => handleNavigate(itm)}
                                        >
                                          {itm.ActivitySubject === undefined &&
                                          itm.ActivitySubject === null &&
                                          itm.ActivitySubject === ""
                                            ? "-"
                                            : itm.ActivitySubject}
                                        </span>

                                        <span className="checkbox-time">
                                          {" "}
                                          {itm.ActivityTime === undefined &&
                                          itm.ActivityTime === null &&
                                          itm.ActivityTime === ""
                                            ? "-"
                                            : itm.ActivityTime}{" "}
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </Form>
                            </>
                          )
                      )}
                    </>
                  ))}
              </div>
            </div>
          </div>
        ) : (
          <PageLoader />
        )}
      </Modal.Body>
    </Modal>
  );
}

function MyVerticallyCenteredModalNotification(props) {
  let page_rights_Account = CommonService.getPageRight("Account");
  let page_rights_LNR = CommonService.getPageRight("LNR");
  let page_rights_RFP = CommonService.getPageRight("RFP");
  let page_rights_Group = CommonService.getPageRight("Group");
  let page_rights_MeetingAndCatering = CommonService.getPageRight("MeetingAndCatering");
  let page_rights_Contacts = CommonService.getPageRight("Contacts");
  let page_rights_Lead = CommonService.getPageRight("Lead");

  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dataSelector: any = useSelector((state: any) => state.session);
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;
  const SelectedPropertyIDs: any = useSelector((state: any) => state.dashboard);

  const [notificationdata, setNotificationdata]: any = useState([]);

  useEffect(() => {
    if (SelectedPropertyIDs.SelectedPropertyID.length > 0) {
      getNotifications();
    }
  }, [SelectedPropertyIDs.SelectedPropertyID]);

  async function getNotifications() {
    // setCurrentData(false);
    // let input: any = {
    //   userID: userId,
    //   propertyIDs: SelectedPropertyIDs.SelectedPropertyID,
    // };
    // setPastActivities([]);
    // setPastActivitiesRow([]);
    // setLoading(true);
    // let res: any = await GetTodoListData(input, Token);
    // if (res.data.success) {
    //   if (res.data !== undefined && res.data !== null) {
    //     if (res.data.data !== undefined && res.data.data) {
    //       setMainTodoResult(res.data.data.dataList1);
    //       setMainTodoResultRow(res.data.data.dataList2);
    //     }
    //   }
    // }
    // setLoading(false);

    let input: any = {
      clientID: clientID,
      userID: userId,
    };
    setLoading(true);
    let res: any = await GetNotificationList(input, Token);
    setLoading(false);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data) {
          setNotificationdata(res.data.data.dataList1);
        }
      }
    }
  }

  function handleClose() {
    // if (window.location.pathname === "/activityList") {
    //   // <PriorActivities />;
    //   props.onHide(true);
    // } else {
    props.onHide(true);
    // }
  }

  async function GetReadNotification(notificationID) {
    let input: any = {
      keyID: notificationID,
    };
    let res: any = await ReadNotification(input, Token);

    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data) {
          // setNotificationdata(res.data.data.dataList1);
          getNotifications();
        }
      }
    }
  }

  function handleNavigate(itm) {
    if (itm.AssociationType_Term.toLowerCase() == "rfp opportunity") {
      if (page_rights_RFP.is_view) {
        navigate("/Opportunity/RFP/details", { state: { OppoID: itm.AssociationID } });
        props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "lnr opportunity") {
      if (page_rights_LNR.is_view) {
        navigate("/Opportunity/LNR/details", { state: { LNROppoID: itm.AssociationID } });
        props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "group opportunity") {
      if (page_rights_Group.is_view) {
        navigate("/Opportunity/Group/details", { state: { GroupOppoID: itm.AssociationID } });
        props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "meeting and catering opportunity") {
      if (page_rights_MeetingAndCatering.is_view) {
        navigate("/Opportunity/MeetingAndCatering/details", {
          state: { MACOppoID: itm.AssociationID },
        });
        props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "lead") {
      if (page_rights_Lead.is_view) {
        navigate("/suspect/details", {
          state: { leadID: itm.AssociationID, leadName: itm.AccountName },
        });
        props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "support") {
      navigate("/support/details", { state: { SupportID: itm.AssociationID } });
      props.onHide(true);
    }
    if (itm.AssociationType_Term.toLowerCase() == "contact") {
      if (page_rights_Contacts.is_view) {
        navigate("/contact/details", {
          state: { ContactID: itm.AssociationID, ContactName: itm.AccountName },
        });
        props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "account") {
      if (page_rights_Account.is_view) {
        navigate("/accounts-view", {
          state: { AccountID: itm.AssociationID, AccountName: itm.AccountName },
        });
        props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "accounts") {
      if (page_rights_Account.is_view) {
        navigate("/accounts-view", {
          state: { AccountID: itm.AssociationID, AccountName: itm.AccountName },
        });
        props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
  }

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter "
      dialogClassName="modal-todo-list"
      className="todo-list-popup right"
      backdropClassName="backdrop-modal"
      scrollable={true}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="to-do-heading">
          Notifications
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {!loading ? (
          notificationdata.length ? (
            notificationdata.map((itm) => (
              <>
                {itm.IsRead === true ? (
                  <Card
                    onClick={() => {
                      handleNavigate(itm);
                    }}
                    className="m-2"
                  >
                    <Card.Body className="notification-card-body">
                      <Card.Text>
                        <div>
                          <span className="fweight">{itm.AssociationType_Term}</span>
                          <span className="f-right">
                            {moment.utc(itm.CreateOn).local().fromNow()}
                          </span>
                        </div>

                        <div className=" mt-2">{itm.Title}</div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ) : (
                  <Card
                    onClick={() => {
                      handleNavigate(itm);
                      GetReadNotification(itm.NotificationID);
                    }}
                    className="notificationUnread m-2"
                  >
                    <Card.Body className="notification-card-body">
                      <Card.Text>
                        <div>
                          <span className="fweight">{itm.AssociationType_Term}</span>
                          <span className="f-right">
                            {moment.utc(itm.CreateOn).local().fromNow()}
                          </span>
                        </div>

                        <div className=" mt-2">{itm.Title}</div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                )}
              </>
            ))
          ) : (
            <Card className=" m-2">
              <Card.Body className="notification-card-body">No record found.</Card.Body>
            </Card>
          )
        ) : (
          <PageLoader />
        )}
      </Modal.Body>
    </Modal>
  );
}

function TopMenu() {
  let page_rights_Lead = CommonService.getPageRight("Lead");
  let page_rights_Account = CommonService.getPageRight("Account");
  let page_rights_Contacts = CommonService.getPageRight("Contacts");
  let page_rights_Activities = CommonService.getPageRight("Activities");
  let page_rights_Calendar = CommonService.getPageRight("Calendar");
  let page_rights_MonthlyReport = CommonService.getPageRight("MonthlyReport");
  let page_rights_MarketingPlan = CommonService.getPageRight("MarketingPlan");
  let page_rights_Email = CommonService.getPageRight("Email");

  const [ShowInbox, setShowInbox] = useState(false);
  const [modalShow, setModalShow] = React.useState<any>(false);
  const [modalShowSuspects, setModalShowSuspects] = React.useState<any>(false);
  const [modalShowActivity, setModalShowActivity] = React.useState(false);
  const [modalShowContact, setModalShowContact] = React.useState(false);
  const [modalShowTodo, setModalShowTodo] = React.useState(false);
  const [modalShowNotification, setModalShowNotification] = React.useState(false);
  const [HelpDesk, setHelpDesk] = useState(false);
  const [commonSearchModule, setCommonSearchModule] = React.useState(false);
  const [headerHotelSearch, setHeaderHotelSearch] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState("");
  const [loading, setLoading] = useState(false);

  const [startWith, setStartWith] = React.useState(true);

  const dataSelector: any = useSelector((state: any) => state.session);
  const AllClientsList: any = useSelector((state: any) => state?.dashboard?.all_clients);
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const ProfileImage: any = dataSelector.auth_UserDetail.U_DisplayAvtar;
  const SelectedPropertyName: any = useSelector(
    (state: any) => state.dashboard.SelectedPropertyName
  );
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showSendMailToClientsModal, setShowSendMailToClientsModal] = React.useState(false);
  const [AllHotels, setAllHotels] = React.useState([]);
  const [NotificationCount, setNotificationCount] = React.useState(0);

  // --------------------------- User Token Refresh Authorized Logic Function ---------------------------
  let localAuthUserDBConnId = localStorage.getItem("DBConnectionID");
  const getUserAuth_AndStayLoggedIn = async (DBConnIdInput) => {
    let Verifyinput = {
      userID: userId,
      dbConnectionID: DBConnIdInput,
      clientID: clientID,
      from: "Client",
    };

    const resdata: any = await VerifyClientLogin(Verifyinput);
    if (resdata !== "" && resdata.data.data) {
      if (
        resdata.data.data.dataList2 &&
        resdata.data.data.dataList2[0] &&
        resdata.data.data.dataList2[0].accessToken
      ) {
        localStorage.setItem(
          "accessToken",
          CryptoJS.AES.encrypt(
            resdata.data.data.dataList2 && resdata.data.data.dataList2[0].accessToken,
            cryptoKey
          )
        );
        // navigate(window.location.pathname);
      }
    } else {
      ToasterError("Something went wrong.");
    }
  }; // Handle Login Confirmation Logic Function:

  let handleInterval: any = null;

  const handleRefreshToken = () => {
    const loginTime = localStorage.getItem("loginTime");
    // Set Time Limit Here For.
    let miliSecOf_16Hours: any = 57600000; // 35000   // miliSecOf_24Hours 86400000;
    let miliSecOf_15Hours: any = 54000000; // 15000   // miliSecOf_23Hours 82800000;
    if (!loginTime) {
      return;
    }
    const currentTime = Date.now();
    const timeLimitExceed = currentTime - parseInt(loginTime, 10);

    // Time limit Exceed.
    if (timeLimitExceed > miliSecOf_16Hours) {
      Mainlogout();
      return;
    } else {
      if (timeLimitExceed > miliSecOf_15Hours) {
        if (handleInterval !== null) {
          clearInterval(handleInterval);
        }
        handleConfirmLoginAlert(miliSecOf_16Hours - timeLimitExceed);
      } else {
        if (handleInterval === null) {
          handleInterval = setInterval(handleRefreshToken, 1000);
        }
      }
    }
  };

  async function handleConfirmLoginAlert(exceed) {
    if (handleInterval !== null) {
      clearInterval(handleInterval);
    }

    let timerInterval: any = null;

    Swal.fire({
      title: "",
      html: "Want to Stay or logged out.<br/> Self logout in <b></b> seconds.",
      icon: "warning",
      timer: exceed,
      timerProgressBar: true,
      didOpen: () => {
        // Swal.showLoading();
        const timer = Swal.getPopup().querySelector("b");
        timerInterval = setInterval(() => {
          // timer.textContent = `${Swal.getTimerLeft()}`;
          timer.textContent = `${Math.ceil(Swal.getTimerLeft() / 1000)}`;
        }, 100);
      },
      showDenyButton: true,
      // denyButtonColor: "#d33",
      denyButtonText: "Logout",
      confirmButtonColor: "#3085d6",
      // confirmButtonText: "Stay Login",
      confirmButtonText: "Stay Logged In",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let dbConnId = "";
        if (localAuthUserDBConnId) {
          var authBytes: any;
          authBytes = CryptoJS.AES.decrypt(localAuthUserDBConnId, cryptoKey);
          dbConnId = authBytes.toString(CryptoJS.enc.Utf8);
        }
        await getUserAuth_AndStayLoggedIn(dbConnId);
        localStorage.setItem("loginTime", Date.now());
        handleInterval = null;
        handleRefreshToken();
      } else if (result.isDenied) {
        Mainlogout();
        clearInterval(timerInterval);
      } else if (result.isDismissed) {
        if (
          result?.dismiss !== undefined &&
          result?.dismiss !== null &&
          result?.dismiss === "timer"
        ) {
          Mainlogout();
          clearInterval(timerInterval);
        }
        // Mainlogout();
        // clearInterval(timerInterval);
      }
    });
  } // Handle Login Confirmation Alert :

  // --------------------------- User Token Refresh Authorized Logic Function ---------------------------

  React.useEffect(() => {
    handleRefreshToken(); // Call for Calculate the time for Refresh Token :

    getSingleUserFullDetails();
    getHotelsList(true);
    getSystemData();
    getNotifications();

    // Close all the different Tabs's Alert.
    window.addEventListener("storage", function (e) {
      // Check if the changed key is the one you're interested in
      if (e.key === "loginTime") {
        // Do something when the key changes
        Swal.close();
        handleInterval = null;
        handleRefreshToken();
      }
    });
  }, []);

  // Fetch the Globle hotels after any Updation.
  useEffect(() => {
    window.addEventListener("storage", async function (e) {
      if (e?.key === "IsHotelUpdate") {
        await getHotelsList(true);
      }
    });
  }, [localStorage.getItem("IsHotelUpdate")]);

  async function getNotifications() {
    let input: any = {
      clientID: clientID,
      userID: userId,
    };
    let res: any = await GetNotificationList(input, Token);

    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data) {
          let tempCount =
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1.filter((item) => item.IsRead === false);
          setNotificationCount(tempCount.length);
        }
      }
    }
    setTimeout(getNotifications, 180000);
  }

  async function getSystemData() {
    let res: any = await getSystemSettingData(Token);
    if (res.data.success) {
      if (res.data.data !== null && res.data.data !== undefined && res.data.data !== "") {
        Cookies.set("SystemSettingDate", res.data.data.dateFormat && res.data.data.dateFormat);
        Cookies.set("SystemSettingTime", res.data.data.timeFormat && res.data.data.timeFormat);
      }
    }
  }
  async function getHotelsList(value) {
    let input: any = {
      clientID: clientID,
      propertyName: "",
      propertyCode: "",
      portfolio: "",
      primaryContactNo: "",
      primaryEmailID: "",
      propertyStatus_Term: "Active",
      hotelCode: "",
      city: "",
      // userID: userTypeID === "78a001cf-27cc-408f-b68f-8bb216cbfef3" ? null : userId && userId,
      userID: userId,
    };
    let res: any = await GetHotelsListData(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data) {
          // if (res.data.data.dataList1 !== undefined && res.data.data && res.data.data.dataList1.length > 0 ) {
          // dispatch(selectedPropertyID(null));
          dispatch(getGlobalHotels(res.data.data.dataList1));
          let temp: any = [];
          res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1.map((item) => {
              temp.push(item.PropertyID);
            });
          setAllHotels(temp);
          if (value === true) {
            dispatch(selectedPropertyID(temp.toString()));
          }
          dispatch(hotelsUpdateData(res.data.data.dataList1));
          // } else {
          //   navigate("/user-profile")
          // }
        } else {
          LoginNewUserAlert();
          navigate("/SetupAndConfigurations/hotels");
        }
      }
    }
  }

  function LoginNewUserAlert() {
    Swal.fire({
      title: "",
      html: "You don't have any assigned properties. <br /> Please add at least one property as it is required to add other data.",
      icon: "warning",
      // showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      // cancelButtonText: "No",
      confirmButtonText: "Ok",
    }).then(async (result) => {
      if (result.isConfirmed) {
      }
    });
  }

  const handleSetSearchInput = (e) => {
    e.preventDefault();
    if (e.target.value) {
      setSearchInput(e.target.value);
    } else {
      // setCommonSearchModule(false);
      setSearchInput("");
    }
  };
  const handleSearch = (e) => {
    if (e.keyCode !== 27) {
      window.addEventListener("keydown", (event) => {
        if (event.keyCode === 27) {
          setCommonSearchModule(false);
          setSearchInput("");
        }
      });
      setCommonSearchModule(true);
    }
  };
  const hotelSearch = (e) => {
    if (e.keyCode !== 27) {
      window.addEventListener("keydown", (event) => {
        if (event.keyCode === 27) {
          setHeaderHotelSearch(false);
        }
      });
      getHotelsList(false);
      setHeaderHotelSearch(true);
    }
  };

  async function getNotificationData() {
    setModalShowNotification(true);

    // let input: any = {
    //   clientID: clientID,
    //   userID: userId,
    // };
    // let res: any = await GetNotificationList(input, Token);

    // if (res.data.success) {
    //   if (res.data !== undefined && res.data !== null) {
    //     if (res.data.data !== undefined && res.data.data) {
    //       setNotificationdata(res.data.data.dataList1);
    //     }
    //   }
    // }
  }

  async function getSingleUserFullDetails() {
    let input: any = {
      keyID: userId,
    };
    setLoading(true);
    let res: any = await getSingleUserSelectedData(input, Token);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== null) {
          // Ring Central Permission Check :
          if (res.data.data.allowRingCentral === false) {
            (function () {
              var rcs = document.createElement("script");
              if (rcs !== null) {
                rcs.src = "https://ringcentral.github.io/ringcentral-embeddable-voice/adapter.js";
                var rcs0 = document.getElementsByTagName("script")[0];
                rcs0.parentNode.insertBefore(rcs, rcs0);
              }
            })();

            var clickToDial = new RingCentralC2D();
            clickToDial.on(RingCentralC2D.events.call, (phoneNumber) => {
              RCAdapter.clickToCall(phoneNumber, false);
            });

            clickToDial.on(RingCentralC2D.events.text, (phoneNumber) => {
              RCAdapter.clickToSMS(phoneNumber);
            });
            setLoading(false);
          } else {
            setLoading(false);
          }
          // let accessMail1: any =
          //   res.data.data.gmailEmailID !== undefined && res.data.data.gmailEmailID
          //     ? res.data.data.gmailEmailID
          //     : null;
          // let accessMail2: any =
          //   res.data.data.smtpIncommingServer !== undefined && res.data.data.smtpIncommingServer
          //     ? res.data.data.smtpIncommingServer
          //     : null;
          // let accessMail3: any =
          //   res.data.data.gmailPassword !== undefined && res.data.data.gmailPassword
          //     ? res.data.data.gmailPassword
          //     : null;
          // let accessMail4: any =
          //   res.data.data.incommingPort !== undefined &&
          //   res.data.data.incommingPort &&
          //   res.data.data.incommingPort !== 0
          //     ? res.data.data.incommingPort
          //     : null;

          // if (accessMail1 && accessMail2 && accessMail3 && accessMail4) {
          //   getAllMailsData(1, 10);
          // } else {
          //   ToasterError("Please setup your mail settings from profile page");
          //   setLoading(false);
          // }
        } else {
          setLoading(false);
        }
      } else {
        ToasterError(res.data.message);
        setLoading(false);
      }
    } else {
      ToasterError(res.data.message);
      setLoading(false);
    }
  }

  return (
    <>
      <div className="menus">
        <div className="menu-col">
          <button
            type="button"
            className="szh-menu-button arrow-class hotels"
            onClick={(e) => hotelSearch(e)}
          >
            {/* Hotels  */}
            <span className="szh-propertyname">
              {SelectedPropertyName ? SelectedPropertyName : "Hotels"}
            </span>
            <span className="arrow-class ms-2">
              <img src={DownArrow} alt="" />
            </span>
          </button>

          {headerHotelSearch ? (
            <div className="header-hotel-search-box-div">
              <HotelSearch setHeaderHotelSearch={setHeaderHotelSearch} />
            </div>
          ) : null}
        </div>
        <div className="menu-col">
          <div
            role="button"
            tabIndex={-1}
            onClick={() => {
              if (page_rights_MarketingPlan.is_view) {
                navigate("/MarketingPlan");
              } else {
                CommonService.unauthorizedAction();
              }
            }}
            onKeyDown={() => {
              if (page_rights_MarketingPlan.is_view) {
                navigate("/MarketingPlan");
              } else {
                CommonService.unauthorizedAction();
              }
            }}
            className="szh-menu-button"
          >
            <span className="szh-propertyname">Marketing Plan Input</span>
            <span className="arrow-class ms-2 mt-4">{/* <img src={DownArrow} alt="" /> */}</span>
          </div>
        </div>

        <div className="menu-col">
          <div
            role="button"
            tabIndex={-1}
            onClick={() => {
              if (page_rights_MonthlyReport.is_view) {
                navigate("/MonthlyReportInput");
              } else {
                CommonService.unauthorizedAction();
              }
            }}
            onKeyDown={() => {
              if (page_rights_MonthlyReport.is_view) {
                navigate("/MonthlyReportInput");
              } else {
                CommonService.unauthorizedAction();
              }
            }}
            className="szh-menu-button"
          >
            <span className="szh-propertyname">Monthly Report Input</span>
            <span className="arrow-class ms-2 mt-4">{/* <img src={DownArrow} alt="" /> */}</span>
          </div>
        </div>
      </div>
      <div className="right-section ms-auto">
        <div className="right-options">
          <div className="search-section z-adjust">
            <Form className="search-section-option">
              {["radio"].map((type: any) => (
                <div key={`inline-${type}`} className="option-field">
                  <Form.Check
                    inline
                    label="Start With"
                    name="group1"
                    type={type}
                    onChange={() => {
                      setStartWith(true);
                    }}
                    checked={startWith}
                    id={`inline-${type}-Start With`}
                    defaultChecked
                  />
                  <Form.Check
                    inline
                    label="Contains"
                    name="group1"
                    onChange={() => {
                      setStartWith(false);
                    }}
                    checked={!startWith}
                    type={type}
                    id={`inline-${type}-Contains`}
                  />
                </div>
              ))}
            </Form>
            <Form className="search-form">
              <input
                type="search"
                placeholder="Search"
                className="form-control"
                value={searchInput}
                onChange={(e) => handleSetSearchInput(e)}
                onKeyUp={(e) => handleSearch(e)}
              />
            </Form>
          </div>
          {commonSearchModule && (
            <div className="header-search-box">
              <HeaderCommonSearch
                AllHotels={AllHotels}
                startWith={startWith}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                commonSearchModule={commonSearchModule}
                setCommonSearchModule={setCommonSearchModule}
              />
            </div>
          )}
        </div>
        <div
          onClick={() => {
            if (page_rights_Email.is_view) {
              setShowSendMailToClientsModal(true);
            } else {
              CommonService.unauthorizedAction();
            }
          }}
          className="cursor-pointer gmail-link"
          tabIndex={-1}
          onKeyDown={() => {
            if (page_rights_Email.is_view) {
              setShowSendMailToClientsModal(true);
            } else {
              CommonService.unauthorizedAction();
            }
          }}
          role={"button"}
        >
          <OverlayTrigger placement="bottom" overlay={<Tooltip>Email</Tooltip>}>
            <img src={GmailIcon} alt="" />
          </OverlayTrigger>
        </div>
        <div className="calendar-minus">
          <Menu
            menuButton={
              <MenuButton className="calendar-icon">
                <OverlayTrigger placement="bottom" overlay={<Tooltip>Calendar</Tooltip>}>
                  <img src={CalendarIcon} alt="" />
                </OverlayTrigger>
              </MenuButton>
            }
          >
            <MenuItem
              onClick={() => {
                if (page_rights_Calendar.is_view) {
                  // navigate("/miscellaneous/calendar");
                  navigate("/calendar");
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            >
              Activity View
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (page_rights_Calendar.is_view) {
                  navigate("/opportunity-group/calendar");
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            >
              Group Block View
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (page_rights_Calendar.is_view) {
                  navigate("/meeting-catering/calendar");
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            >
              Meeting Room Block View
            </MenuItem>
          </Menu>
        </div>
        <div className="add-quick-butt">
          {["end"].map((direction, key) => (
            <Dropdown key={key}>
              <Dropdown.Toggle id={`dropdown-button-drop-${direction}`}>
                <span className="plus-icon">
                  <OverlayTrigger
                    key={key}
                    placement="bottom"
                    overlay={<Tooltip>Quick Add</Tooltip>}
                  >
                    <img src={PlusCircle} alt="" />
                  </OverlayTrigger>
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    if (page_rights_Lead.is_create) {
                      setModalShowSuspects(true);
                    } else {
                      CommonService.unauthorizedAction();
                    }
                  }}
                >
                  Add Lead
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    if (page_rights_Account.is_create) {
                      setModalShow(true);
                    } else {
                      CommonService.unauthorizedAction();
                    }
                  }}
                >
                  Add Account
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    if (page_rights_Contacts.is_create) {
                      setModalShowContact(true);
                    } else {
                      CommonService.unauthorizedAction();
                    }
                  }}
                >
                  Add Contact
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    if (page_rights_Activities.is_create) {
                      setModalShowActivity(true);
                    } else {
                      CommonService.unauthorizedAction();
                    }
                  }}
                >
                  Add Activity
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ))}
        </div>
        <div className="to-do-list">
          <div className="todo-dropdown-icon">
            <button className="tolist-icon" onClick={() => setModalShowTodo(true)}>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>Todo List</Tooltip>}>
                <img src={ToList} alt="" />
              </OverlayTrigger>
            </button>
          </div>
        </div>

        <div className="notification-bell">
          <OverlayTrigger placement="bottom" overlay={<Tooltip>Notification</Tooltip>}>
            <i
              onClick={getNotificationData}
              style={{ fontSize: "24px", color: "#000000" }}
              className="fa-bell fa-light me-2 notification"
            >
              <span className="badge">{NotificationCount > 99 ? "99+" : NotificationCount}</span>
            </i>
          </OverlayTrigger>
        </div>

        <div className="support-topmenu">
          <OverlayTrigger placement="bottom" overlay={<Tooltip>Support</Tooltip>}>
            <i
              onClick={() => setHelpDesk(true)}
              style={{ fontSize: "24px", color: "#000000" }}
              className="fa-question fa-light me-2"
            ></i>
          </OverlayTrigger>
        </div>

        <div className="profile-section">
          <Menu
            menuButton={
              <MenuButton className="profile-icon">
                <span className="user ">
                  <img
                    src={ProfileImage ? ProfileImage : DefaultUserlogo}
                    className="rounded-circle"
                    alt="user"
                    width="45"
                    height="45"
                  />
                </span>
              </MenuButton>
            }
          >
            <MenuItem
              onClick={() => {
                navigate("/user-profile");
              }}
            >
              <i className="fa-light fa-user"></i> &nbsp; My Profile
            </MenuItem>
            {AllClientsList?.length > 1 && (
              <MenuItem
                onClick={() => {
                  dispatch(getAllClients(AllClientsList));
                  navigate("/clientSelection", { state: { data: AllClientsList } });
                }}
              >
                <i
                  className="fa fa-refresh me-2"
                  style={{ fontSize: "17px", fontWeight: "300" }}
                ></i>{" "}
                Switch Account
              </MenuItem>
            )}

            <MenuItem
              onClick={() => {
                Mainlogout();
                // localStorage.clear();
                // window.location.reload();
                // Cookies.remove("SystemSettingDate");
              }}
            >
              <i className="fa-light fa-arrow-right-from-bracket"></i> &nbsp; Log out
            </MenuItem>
          </Menu>
        </div>
      </div>
      {modalShow && <AddAccountModal modalShow={modalShow} setModalShow={setModalShow} />}

      {modalShowSuspects && (
        <AddLeadModal
          modalShowSuspects={modalShowSuspects}
          setModalShowSuspects={setModalShowSuspects}
        />
      )}

      {modalShowContact && (
        <AddContactModal
          modalShowContact={modalShowContact}
          setModalShowContact={setModalShowContact}
        />
      )}

      {modalShowActivity && (
        <AddActivityModal
          setModalShowActivity={setModalShowActivity}
          modalShowActivity={modalShowActivity}
        />
      )}
      {showSendMailToClientsModal && (
        <SendEmailToClients
          setModalShow={setShowSendMailToClientsModal}
          modalShow={showSendMailToClientsModal}
        />
      )}

      {modalShowTodo === true && (
        <MyVerticallyCenteredModalTodo
          show={modalShowTodo}
          onHide={() => {
            setModalShowTodo(false);
          }}
        />
      )}

      {modalShowNotification === true && (
        <MyVerticallyCenteredModalNotification
          show={modalShowNotification}
          onHide={() => {
            getNotifications();
            setModalShowNotification(false);
          }}
        />
      )}

      {HelpDesk && (
        <AddHelpDesk
          show={HelpDesk}
          onHide={() => setHelpDesk(false)}
          // getSupportdata={getSupportdata}
        ></AddHelpDesk>
      )}

      {ShowInbox && <MailInbox setModalShow={setShowInbox} modalShow={ShowInbox} />}
    </>
  );
}

export default TopMenu;
