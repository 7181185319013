/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { PatternFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import BlackCancelIcon from "../Asset/Images/Icons/blackCancelIcon.svg";
import UserSettingIcon from "../Asset/Images/Icons/UserSetting.svg";

import WhiteSaveIcon from "../Asset/Images/Icons/whiteSaveIcon.svg";
import PageLoader from "../Component/Loader/PageLoader";
import { getGlobalHotels, hotelsUpdateData } from "../Redux/actions";
import {
  fnCheckValidationOfObject,
  ToasterError,
  ToasterSuccess,
} from "../Services/CommonServices";
import { GetHotelsListData } from "../Services/Setup&Config/HotelService";
import {
  blockUser,
  CheckUserLimit,
  getUserDetailedDataByID,
  SaveUserDetail,
} from "../Services/Setup&Config/UserSetupService";
import ProdGoals from "./ProdGoals";

function UserModal(props) {
  const [loading, setLoading] = useState(false);
  const [OnProcessHide, setOnProcessHide] = React.useState(false);
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const userId: any = dataSelector.auth_UserDetail.U_UserID;

  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const userTypeID: any = dataSelector.auth_UserDetail.U_UserTypeID;

  const dispatch = useDispatch();

  const [showProdGoalsModal, setShowProdGoalsModal] = useState(false);

  const [prodInputs, setProdInputs] = useState({});

  const [prodInputsEr, setProdInputsEr] = useState({});

  let init: any = {
    IsBlock: false,
    CountryCode: "",
    Add1: "",
    Add2: "",
    City: "",
    ContactNo: "",
    Country: "",
    DisplayName: "",
    EmailID: "",
    EmployeeID: "00000000-0000-0000-0000-000000000000",
    Ext: "",
    ExtraNo: "",
    UserName: "",
    FName: "",
    IsGoogleAuthenization: "",
    IsKriyaAuthenization: "",
    IsTSA: false,
    LName: "",
    MobileNo: "",
    Notes: "",
    State: "",
    UserID: "00000000-0000-0000-0000-000000000000",
    Zipcode: "",
    property: "",
    password: "",
    portfolio: [],
    role: [],
    PropertyID: "",
    IsSendMail: true,
    errors: {
      FName: "",
      LName: "",
      EmailID: "",
      property: "",
      ContactNo: "",
      // UserName: "",
      // Notes: "",
      ValidationRules: [
        // {
        //   FieldName: "UserName",
        //   ValidationType: "required",
        //   ValidationMessage: "This Field is required.",
        // },
        {
          FieldName: "FName",
          ValidationType: "required",
          ValidationMessage: "This Field is required.",
        },
        {
          FieldName: "LName",
          ValidationType: "required",
          ValidationMessage: "This Field is required.",
        },
        {
          FieldName: "EmailID",
          ValidationType: "required",
          ValidationMessage: "This Field is required.",
        },
        {
          FieldName: "EmailID",
          ValidationType: "email",
          ValidationMessage: "Please enter valid email.",
        },
        {
          FieldName: "property",
          ValidationType: "required",
          ValidationMessage: "Select Hotels please.",
        },
        {
          FieldName: "ContactNo",
          ValidationType: "range",
          ValidationMessage: "Must enter 10 digits.",
        },
      ],
    },
  };
  // let ValidUserName: any = false;
  const [UserDetail, setUserDetail]: any = React.useState(init);
  const [UserRole, setUserRole]: any = React.useState([]);
  const [UserPortfollio, setUserPortfollio]: any = React.useState([]);
  const [UserProperty, setUserProperty]: any = React.useState([]);
  const [PortfolioHotels, setPortfolioHotels]: any = React.useState([]);
  const [TempRole, setTempRole]: any = React.useState([]);
  const [IsStatusSelect, setIsStatusSelect] = React.useState({ status: false, message: "" });

  const [hotelChangeLoading, sethotelChangeLoading] = React.useState(false); // Hotel Selection Loader State.

  React.useEffect(() => {
    sethotelChangeLoading(true);
    getUserFullDetails();
  }, []);

  // Update Globle hotels List: 
  async function getHotelsList() {
    let input: any = {
      clientID: clientID,
      propertyName: "",
      propertyCode: "",
      portfolio: "",
      primaryContactNo: "",
      primaryEmailID: "",
      propertyStatus_Term: "Active",
      hotelCode: "",
      city: "",
      // userID: userTypeID === "78a001cf-27cc-408f-b68f-8bb216cbfef3" ? null : userId && userId,
      userID: userId,
    };

    let res: any = await GetHotelsListData(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          dispatch(hotelsUpdateData(res.data.data.dataList1));

          dispatch(getGlobalHotels(res.data.data.dataList1));
          // let temp: any = [];
          // res.data.data.dataList1.length > 0 &&
          //   res.data.data.dataList1.map((item) => {
          //     temp.push(item.PropertyID);
          //   });
          // setAllHotels(temp);
          // if(value === true){
          // dispatch(selectedPropertyID(temp.toString()));
          // }
          // dispatch(hotelsUpdateData(res.data.data.dataList1));
        }
      }
    }
  }

  let markPortfolio: any = [];
  async function getUserFullDetails() {
    let input: any = {
      userID: "00000000-0000-0000-0000-000000000000", // props.UserID.userID,
      employeeID: "00000000-0000-0000-0000-000000000000", // props.UserID.employeeID,
    };
    if (
      props.UserID.userID !== "00000000-0000-0000-0000-000000000000" &&
      props.UserID.userID !== null &&
      props.UserID.userID !== undefined &&
      props.UserID.employeeID !== "00000000-0000-0000-0000-000000000000" &&
      props.UserID.employeeID !== undefined &&
      props.UserID.employeeID !== null
    ) {
      input.userID = props.UserID.userID;
      input.employeeID = props.UserID.employeeID;
      // setLoading(true);
    }
    let res: any = await getUserDetailedDataByID(input, Token);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setUserRole(res.data.data.role);
          setUserPortfollio(res.data.data.portfolio);
          setUserProperty(res.data.data.property);

          let tmpProdIn: any = {};
          let tmpProdEr: any = {};
          res.data.data.salesGroupSettings.forEach((item_g) => {
            tmpProdIn = {
              ...tmpProdIn,
              [item_g.PropertyID]: {
                propertyID: item_g.PropertyID ? item_g.PropertyID : "",
                // roleID: item_g.RoleID ?item_g.RoleID:"",
                clientVisit: item_g.ClientVisit ? item_g.ClientVisit : 0,
                siteVisit: item_g.SiteVisit ? item_g.SiteVisit : 0,
                blitz: item_g.Blitz ? item_g.Blitz : 0,
                telemarketing: item_g.Telemarketing ? item_g.Telemarketing : 0,
                networking: item_g.Networking ? item_g.Networking : 0,
                hotelName: item_g.PropertyName ? item_g.PropertyName : "",
              },
            };
            tmpProdEr = {
              ...tmpProdEr,
              [item_g.PropertyID]: {
                propertyID: "",
                // roleID :""
              },
            };
          });
          setProdInputsEr(tmpProdEr);
          setProdInputs(tmpProdIn);
          // setPortfolioHotels(res.data.data.property);

          // set the portfolio at edit time :
          let temp: any = [];
          res.data.data.property.map((item) => {
            item.IsSelected === true &&
              temp.push({
                portfolioID: item.PortfolioIDs,
                value: item.PropertyID,
                label: item.PropertyCode + " " + item.PropertyName,
              });
          });
          res.data.data.portfolio.map((port) => {
            temp.map((t) => {
              t.portfolioID &&
                t.portfolioID.toLowerCase().trim() === port.PortfolioID &&
                markPortfolio.push(port.PortfolioID);
            });
          });

          if (
            props.UserID.userID !== "00000000-0000-0000-0000-000000000000" &&
            props.UserID.userID !== null &&
            props.UserID.userID !== undefined
          ) {
            setPortfolioHotels(temp);
            // setUserPortfollio(markPortfolio);
          }
          // Set the roles at edit time in user Roles. :
          let tempRole: any = [];
          res.data.data.role.map((item) => {
            item.IsSelected === true && tempRole.push(item.RoleID);
          });
          setTempRole(tempRole);

          if (res.data.data.user.length > 0) {
            // setLogoUpload(res.data.data.user[0].DisplayAvtar);
            setUserDetail({
              ...UserDetail,
              Add1: res.data.data.user[0].Add1 !== "null" ? res.data.data.user[0].Add1 : "",
              Add2: res.data.data.user[0].Add2 !== "null" ? res.data.data.user[0].Add2 : "",
              City: res.data.data.user[0].City !== "null" ? res.data.data.user[0].City : "",
              UserName:
                res.data.data.user[0].UserName !== "null" ? res.data.data.user[0].UserName : "",
              ContactNo:
                res.data.data.user[0].ContactNo && res.data.data.user[0].ContactNo.includes(" ")
                  ? res.data.data.user[0].ContactNo.split(" ")[1]
                  : res.data.data.user[0].ContactNo !== "null"
                  ? res.data.data.user[0].ContactNo
                  : "",

              CountryCode:
                res.data.data.user[0].ContactNo && res.data.data.user[0].ContactNo.includes(" ")
                  ? res.data.data.user[0].ContactNo.split(" ")[0]
                  : "",

              Country:
                res.data.data.user[0].Country !== "null" ? res.data.data.user[0].Country : "",
              DisplayName:
                res.data.data.user[0].DisplayName !== "null"
                  ? res.data.data.user[0].DisplayName
                  : "",
              EmailID: res.data.data.user[0].EmailID,
              EmployeeID: res.data.data.user[0].EmployeeID,
              Ext: res.data.data.user[0].Ext !== "null" ? res.data.data.user[0].Ext : "",
              ExtraNo:
                res.data.data.user[0].ExtraNo !== "null" ? res.data.data.user[0].ExtraNo : "",
              FName: res.data.data.user[0].FName !== "null" ? res.data.data.user[0].FName : "",
              IsGoogleAuthenization: res.data.data.user[0].IsGoogleAuthenization,
              // IsKriyaAuthenization: res.data.data.user[0].IsKriyaAuthenization,
              IsTSA: res.data.data.user[0].IsTSA !== null ? res.data.data.user[0].IsTSA : false,
              LName: res.data.data.user[0].LName !== "null" ? res.data.data.user[0].LName : "",
              MobileNo:
                res.data.data.user[0].MobileNo !== "null" ? res.data.data.user[0].MobileNo : "",
              Notes: res.data.data.user[0].Notes !== "null" ? res.data.data.user[0].Notes : "",
              Password:
                res.data.data.user[0].Password !== "null" ? res.data.data.user[0].Password : "",
              State: res.data.data.user[0].State !== "null" ? res.data.data.user[0].State : "",
              UserID: res.data.data.user[0].UserID !== "null" ? res.data.data.user[0].UserID : "",
              Zipcode:
                res.data.data.user[0].Zipcode !== "null" ? res.data.data.user[0].Zipcode : "",
              IsBlock:
                res.data.data.user[0].IsBlock !== "null" ? res.data.data.user[0].IsBlock : "",
              PropertyID: markPortfolio,
              property: temp,
              // IsSendMail : res.data.data.user[0].IsSendMail,
              IsSendMail: false,
            });
          }
        }
      }
    }
    // setLoading(false);
    sethotelChangeLoading(false);
  }

  let MyOption: any = [];
  UserProperty &&
    UserProperty.map((item: any) => {
      MyOption.push({
        portfolioID: item.PortfolioIDs,
        value: item.PropertyID,
        label: item.PropertyCode + " " + item.PropertyName,
      });
    });

  function handleClose() {
    setUserRole([]);
    setUserPortfollio([]);
    setUserProperty([]);
    setUserDetail(init);
    props.onHide(false);
  }

  function SelectedRoleValues(event: any, item: any) {
    if (event.target.checked === true) {
      setTempRole((t) => [...t, item.RoleID]);
    }
    if (event.target.checked === false) {
      for (var i = 0; i < TempRole.length; i++) {
        if (TempRole[i] === event.target.value) {
          TempRole.splice(i, 1);
          i--;
        }
      }
      setTempRole(TempRole);
    }
  }

  function SelectedPortfolioValues(e: any, item: any) {
    let result: any = [];
    let term = item.PortfolioID.toUpperCase();
    for (var i in UserProperty) {
      if (e.target.checked === true) {
        if (
          UserProperty[i].PortfolioIDs !== null &&
          UserProperty[i].PortfolioIDs !== undefined &&
          UserProperty[i].PortfolioIDs.toUpperCase().trim() === term
        ) {
          result.push({
            portfolioID: UserProperty[i].PortfolioIDs,
            value: UserProperty[i].PropertyID,
            label: UserProperty[i].PropertyName,
          });
        }
      } else {
        for (var j = 0; j < PortfolioHotels.length; j++) {
          if (PortfolioHotels[j].portfolioID.toLowerCase().trim() === item.PortfolioID) {
            PortfolioHotels.splice(j, 1);
            j--;
          }
        }
      }
    }

    setUserDetail({
      ...UserDetail,
      ["property"]: result,
    });

    setPortfolioHotels((temp) => [...temp, ...result]);
  }

  function SelectedHotel(event) {
    setPortfolioHotels(event);

    if (event.length !== 0) {
      setUserDetail({
        ...UserDetail,
        ["property"]: event,
      });
    } else {
      setUserDetail({
        ...UserDetail,
        ["property"]: "",
      });
    }
  }
  async function SaveUser() {
    let temp: any = [];
    PortfolioHotels.map((item: any) => temp.push(item.value));
    PortfolioHotels;
    let tempRole: any = [];
    UserRole.map((item) => tempRole.push(item.RoleID));

    if (!TempRole.length) {
      let obj = fnCheckValidationOfObject(UserDetail);

      setIsStatusSelect({ status: true, message: "Please select atleast one Role." });
    } else {
      setIsStatusSelect({ status: false, message: "" });

      // if (
      //   UserDetail.UserName !== null &&
      //   UserDetail.UserName !== "" &&
      //   UserDetail.UserName !== undefined
      // ) {
      //   let pattern: any = /^[a-zA-Z0-9]+([_]?[a-zA-Z0-9]+)*$/;
      //   if (pattern.test(UserDetail.UserName)) {
      //     let element: any = document.getElementById("UserNameValue");
      //     element !== null && element.classList.remove("ValidUserNameboarder");
      //     ValidUserName = false;
      //   } else {
      //     let element: any = document.getElementById("UserNameValue");
      //     element !== null && element.classList.add("ValidUserNameboarder");
      //     toast.error("Please Enter Valid User Name.. Eg: Admin_123, Christine_ronning, 123, Admin", {
      //       theme: "colored",
      //     });

      //     ValidUserName = true;
      //   }
      // }

      let obj = fnCheckValidationOfObject(UserDetail);
      setUserDetail({
        ...obj.obj,
      });

      // if (obj.isValid && ValidUserName === false) {
      if (obj.isValid) {
        let lstSalesGroupSettingsTemp: any = [];
        Object.keys(prodInputs).forEach((key_h) => {
          lstSalesGroupSettingsTemp = [
            ...lstSalesGroupSettingsTemp,
            {
              ...prodInputs[key_h],
              propertyID: prodInputs[key_h].propertyID ? prodInputs[key_h].propertyID : "",
              // roleID: prodInputs[key_h].roleID ?prodInputs[key_h].roleID:"",
              clientVisit: prodInputs[key_h].clientVisit
                ? parseInt(prodInputs[key_h].clientVisit)
                : 0,
              siteVisit: prodInputs[key_h].siteVisit ? parseInt(prodInputs[key_h].siteVisit) : 0,
              blitz: prodInputs[key_h].blitz ? parseInt(prodInputs[key_h].blitz) : 0,
              telemarketing: prodInputs[key_h].telemarketing
                ? parseInt(prodInputs[key_h].telemarketing)
                : 0,
              networking: prodInputs[key_h].networking ? parseInt(prodInputs[key_h].networking) : 0,
            },
          ];
        });
        if (
          UserDetail.UserID !== undefined &&
          UserDetail.UserID !== null &&
          UserDetail.UserID !== "00000000-0000-0000-0000-000000000000"
        ) {
          // Edit

          let formData: any = new FormData();
          formData.append("UserID", UserDetail.UserID);
          formData.append("EmployeeID", UserDetail.EmployeeID);
          // formData.append("IsGoogleAuthenization", UserDetail.IsGoogleAuthenization);
          // formData.append("IsKriyaAuthenization", UserDetail.IsKriyaAuthenization);
          formData.append("EmailID", UserDetail.EmailID);
          formData.append("UserName", UserDetail.UserName);
          formData.append("FName", UserDetail.FName);
          formData.append("LName", UserDetail.LName);
          formData.append("IsTSA", UserDetail.IsTSA);
          formData.append("Add1", UserDetail.Add1);
          formData.append("Add2", UserDetail.Add2);
          formData.append("City", UserDetail.City);
          formData.append("State", UserDetail.State);
          formData.append("Zipcode", UserDetail.Zipcode);
          formData.append("Country", UserDetail.Country);
          formData.append("ContactNo", UserDetail.CountryCode + " " + UserDetail.ContactNo);
          formData.append("MobileNo", UserDetail.MobileNo);
          formData.append("ExtraNo", UserDetail.ExtraNo);
          formData.append(
            "Notes",
            UserDetail.Notes !== "null" && UserDetail.Notes !== null ? UserDetail.Notes : ""
          );
          formData.append("Ext", UserDetail.Ext);
          formData.append("PropertyID", temp.toString());
          formData.append("RoleID", TempRole.toString());
          formData.append("DisplayAvtar", "");
          formData.append("IsSendMail", UserDetail.IsSendMail);
          formData.append(
            "FormFiles",
            null // typeof Logo.link[0] !== "undefined" && Logo.link[0] ? Logo.link[0] : null
          );

          lstSalesGroupSettingsTemp.forEach((itm, i) => {
            formData.append(`lstSalesGroupSettings[${i}].propertyID`, itm.propertyID);
            // formData.append(`lstSalesGroupSettings[${i}].roleID`, itm.roleID);
            formData.append(
              `lstSalesGroupSettings[${i}].roleID`,
              "00000000-0000-0000-0000-000000000000"
            );
            formData.append(`lstSalesGroupSettings[${i}].clientVisit`, itm.clientVisit);
            formData.append(`lstSalesGroupSettings[${i}].siteVisit`, itm.siteVisit);
            formData.append(`lstSalesGroupSettings[${i}].blitz`, itm.blitz);
            formData.append(`lstSalesGroupSettings[${i}].telemarketing`, itm.telemarketing);
            formData.append(`lstSalesGroupSettings[${i}].networking`, itm.networking);
          });

          setOnProcessHide(true);
          let res: any = await SaveUserDetail(formData, Token);
          setOnProcessHide(false);
          if (res.data.success) {
            if (res.data !== undefined && res.data !== null) {
              if (res.data.statusCode === 200) {
                ToasterSuccess(res.data.message);
                props.getUserData();
                props.onHide(false);
                getHotelsList();
              } else if (res.data.statusCode === 208) {
                ToasterError(res.data.message);
              }
            } else {
              ToasterError(res.data.message);
            }
          } else {
            ToasterError(res.data.message);
          }
        } else {
          // Add
          let temp: any = [];
          PortfolioHotels.map((item: any) => temp.push(item.value));

          let formData: any = new FormData();
          formData.append("UserID", "00000000-0000-0000-0000-000000000000");
          formData.append("EmployeeID", UserDetail.EmployeeID);
          formData.append("UserName", UserDetail.UserName);
          formData.append("IsGoogleAuthenization", UserDetail.IsGoogleAuthenization);
          formData.append("IsKriyaAuthenization", UserDetail.IsKriyaAuthenization);
          formData.append("EmailID", UserDetail.EmailID);
          formData.append("FName", UserDetail.FName);
          formData.append("LName", UserDetail.LName);
          formData.append("IsTSA", UserDetail.IsTSA);
          formData.append("Add1", UserDetail.Add1);
          formData.append("Add2", UserDetail.Add2);
          formData.append("City", UserDetail.City);
          formData.append("State", UserDetail.State);
          formData.append("Zipcode", UserDetail.Zipcode);
          formData.append("Country", UserDetail.Country);
          formData.append("ContactNo", UserDetail.CountryCode + " " + UserDetail.ContactNo);
          formData.append("MobileNo", UserDetail.MobileNo);
          formData.append("ExtraNo", UserDetail.ExtraNo);
          formData.append(
            "Notes",
            UserDetail.Notes !== "null" && UserDetail.Notes !== null ? UserDetail.Notes : ""
          );
          formData.append("Ext", UserDetail.Ext);
          formData.append("PropertyID", temp);
          formData.append("RoleID", TempRole);
          formData.append("DisplayAvtar", "");
          formData.append("IsSendMail", UserDetail.IsSendMail);
          formData.append(
            "FormFiles",
            // typeof Logo.link[0] !== "undefined" && Logo.link[0] ? Logo.link[0] : null
            null
          );
          lstSalesGroupSettingsTemp.forEach((itm, i) => {
            formData.append(`lstSalesGroupSettings[${i}].propertyID`, itm.propertyID);
            // formData.append(`lstSalesGroupSettings[${i}].roleID`, itm.roleID);
            formData.append(
              `lstSalesGroupSettings[${i}].roleID`,
              "00000000-0000-0000-0000-000000000000"
            );
            formData.append(`lstSalesGroupSettings[${i}].clientVisit`, itm.clientVisit);
            formData.append(`lstSalesGroupSettings[${i}].siteVisit`, itm.siteVisit);
            formData.append(`lstSalesGroupSettings[${i}].blitz`, itm.blitz);
            formData.append(`lstSalesGroupSettings[${i}].telemarketing`, itm.telemarketing);
            formData.append(`lstSalesGroupSettings[${i}].networking`, itm.networking);
          });

          // let input: any = {
          //   userID: UserDetail.UserID,
          //   employeeID: UserDetail.EmployeeID,
          //   isGoogleAuthenization: UserDetail.IsGoogleAuthenization,
          //   isKriyaAuthenization: UserDetail.IsKriyaAuthenization,
          //   emailID: UserDetail.EmailID,
          //   fName: UserDetail.FName,
          //   lName: UserDetail.LName,
          //   isTSA: UserDetail.IsTSA,
          //   add1: UserDetail.Add1,
          //   add2: UserDetail.Add2,
          //   city: UserDetail.City,
          //   state: UserDetail.State,
          //   zipcode: UserDetail.Zipcode,
          //   country: UserDetail.Country,
          //   contactNo: UserDetail.CountryCode + " " + UserDetail.ContactNo,
          //   mobileNo: UserDetail.MobileNo,
          //   extraNo: UserDetail.ExtraNo,
          //   notes: UserDetail.Notes,
          //   ext: UserDetail.Ext,
          //   propertyID: temp,
          //   roleID: TempRole,
          //   displayAvtar: "",
          // };
          setOnProcessHide(true);
          let res: any = await SaveUserDetail(formData, Token);

          setOnProcessHide(false);
          if (res.data.success) {
            if (res.data !== undefined && res.data !== null) {
              if (res.data.statusCode === 200) {
                ToasterSuccess(res.data.message);
                props.getUserData();
                props.onHide(false);
                getHotelsList();
              } else if (res.data.statusCode === 208) {
                ToasterError(res.data.message);
              }
            } else {
              ToasterError(res.data.message);
            }
          } else {
            ToasterError(res.data.message);
          }
        }
      }
    }
  }

  const [LoadingBlock, setLoadingBlock] = React.useState(false);

  async function IsBlock(data: any) {
    if (data === false) {
      //Check Users Limit
      let rescheck: any = await CheckUserLimit(Token);

      if (rescheck.data.success) {
        if (rescheck.data !== undefined && rescheck.data !== null) {
          if (rescheck.data.data === true) {
            let input = {
              userID: UserDetail.UserID,
              employeeID: UserDetail.EmployeeID,
              isBlock: data,
            };

            setUserDetail({
              ...UserDetail,
              ["IsBlock"]: data,
            });
            setLoadingBlock(true);
            let res: any = await blockUser(input, Token);
            setLoadingBlock(false);
            if (res.data.success) {
              if (res.data.statusCode === 200) {
                if (data === true) {
                  ToasterSuccess("Blocked SuccessFully");
                } else {
                  ToasterSuccess("Unblocked SuccessFully");
                }
              }
            } else {
              ToasterError("Some thing went wrong.");
            }
          } else {
            ToasterError("Contracted User Limit Over ");
          }
        }
      }
    } else {
      let input = {
        userID: UserDetail.UserID,
        employeeID: UserDetail.EmployeeID,
        isBlock: data,
      };

      setUserDetail({
        ...UserDetail,
        ["IsBlock"]: data,
      });
      setLoadingBlock(true);
      let res: any = await blockUser(input, Token);
      setLoadingBlock(false);
      if (res.data.success) {
        if (res.data.statusCode === 200) {
          if (data === true) {
            ToasterSuccess("Blocked SuccessFully");
          } else {
            ToasterSuccess("Unblocked SuccessFully");
          }
        }
      } else {
        ToasterError("Some thing went wrong.");
      }
    }
  }

  // function UserNameCheck(e: any) {
  //   if (e.target.value !== "" && e.target.value !== null && e.target.value !== undefined) {
  //     let pattern: any = /^[a-zA-Z0-9]+([_]?[a-zA-Z0-9]+)*$/;
  //     if (pattern.test(e.target.value)) {
  //       setUserDetail({
  //         ...UserDetail,
  //         ["UserName"]: e.target.value,
  //       });
  //       let element: any = document.getElementById("UserNameValue");
  //       element !== null && element.classList.remove("addValidBorder");
  //     } else {
  //       setUserDetail({
  //         ...UserDetail,
  //         ["UserName"]: e.target.value,
  //       });
  //       let element: any = document.getElementById("UserNameValue");
  //       element !== null && element.classList.add("addValidBorder");
  //     }
  //   }else{
  //     setUserDetail({
  //       ...UserDetail,
  //       ["UserName"]:"",
  //     });
  //   }
  // }
  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-50w"
        className="quickAdd-popup"
        scrollable={true}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="d-flex align-items-center justify-content-between w-100"
          >
            {UserDetail.UserID !== "00000000-0000-0000-0000-000000000000" ? (
              <div> Edit User</div>
            ) : (
              <div> Add User</div>
            )}

            <>
              <div className=" green-toggle-switch d-flex align-items-center">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={UserDetail.IsTSA === true ? true : false}
                    onChange={(e) => {
                      setUserDetail({
                        ...UserDetail,
                        ["IsTSA"]: e.target.checked,
                      });
                    }}
                  />
                  <span className="slider round"></span>
                </label>
                <p className="m-0 user-status-text">2 - Step Authentication</p>
              </div>
            </>

            {UserDetail.UserID !== "00000000-0000-0000-0000-000000000000" &&
              UserDetail.UserID !== null &&
              UserDetail.UserID !== undefined && (
                <>
                  <div className=" green-toggle-switch d-flex align-items-center">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={UserDetail.IsBlock === true ? true : false}
                        onChange={(e) => {
                          IsBlock(e.target.checked);
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                    <p className="m-0 user-status-text">
                      Status( {UserDetail.IsBlock === true ? "Block" : "Un-Block"} )
                    </p>
                  </div>
                </>
              )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!loading ? (
            <>
              {LoadingBlock === false ? (
                <>
                  <Row className="mb-2">
                    <Col xs={6} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="First Name">
                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          value={UserDetail.FName}
                          onChange={(e) =>
                            setUserDetail({
                              ...UserDetail,
                              ["FName"]: e.target.value,
                            })
                          }
                          isInvalid={UserDetail.errors && UserDetail.errors.FName}
                        />
                        {UserDetail.errors && UserDetail.errors.FName && (
                          <Form.Control.Feedback type="invalid">
                            {UserDetail.errors.FName}
                          </Form.Control.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col xs={6} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="Last Name">
                        <Form.Control
                          type="text"
                          placeholder="Last Name"
                          value={UserDetail.LName}
                          onChange={(e) =>
                            setUserDetail({
                              ...UserDetail,
                              ["LName"]: e.target.value,
                            })
                          }
                          isInvalid={UserDetail.errors && UserDetail.errors.LName}
                        />
                        {UserDetail.errors && UserDetail.errors.LName && (
                          <Form.Control.Feedback type="invalid">
                            {UserDetail.errors.LName}
                          </Form.Control.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    {/* <Col xs={4} className="mb-3">
                    <FloatingLabel controlId="UserName" label="User Name">
                      <Form.Control
                        type="text"
                        id="UserNameValue"
                        placeholder="User Name"
                        onChange={
                          (e) =>
                            setUserDetail({
                              ...UserDetail,
                              ["UserName"]: e.target.value,
                            })
                          // UserNameCheck(e)
                        }
                        value={UserDetail.UserName}
                        isInvalid={UserDetail.errors && UserDetail.errors.UserName}
                        disabled={
                          UserDetail.UserID !== "" &&
                          UserDetail.UserID !== null &&
                          UserDetail.UserID !== undefined &&
                          UserDetail.UserID !== "00000000-0000-0000-0000-000000000000" &&
                          true
                        }
                      />
                      {UserDetail.errors && UserDetail.errors.UserName && (
                        <Form.Control.Feedback type="invalid">
                          {UserDetail.errors.UserName}
                        </Form.Control.Feedback>
                      )}
                      {ValidUserName && ValidUserName === true && (
                        <Form.Control.Feedback type="invalid">
                          {"Please Enter Valid User Name"}
                        </Form.Control.Feedback>
                      )}
                    </FloatingLabel>
                  </Col> */}
                  </Row>
                  {/* {props.UserID && props.UserID.userID !== "00000000-0000-0000-0000-000000000000" && (
                    <Row className="mb-2">
                      <Col xs={6} className="mb-3">
                        <FloatingLabel controlId="floatingPassword" label="UserName">
                          <Form.Control
                            type="text"
                            disabled={
                              props.UserID &&
                              props.UserID.userID !== "00000000-0000-0000-0000-000000000000"
                                ? true
                                : false
                            }
                            placeholder="UserName"
                            value={UserDetail.UserName}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col xs={6} className="mb-3">
                        <FloatingLabel controlId="floatingPassword" label="Passsword">
                          <Form.Control
                            type="text"
                            disabled={
                              props.UserID &&
                              props.UserID.userID !== "00000000-0000-0000-0000-000000000000"
                                ? true
                                : false
                            }
                            placeholder="Passsword"
                            value={UserDetail.Password}
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                  )} */}
                  <Row className="mb-2">
                    <Col xs={6} className="mb-3">
                      <FloatingLabel controlId="floatingPassword" label="Email">
                        <Form.Control
                          type="email"
                          // disabled={
                          //   props.UserID &&
                          //   props.UserID.userID !== "00000000-0000-0000-0000-000000000000"
                          //     ? true
                          //     : false
                          // }
                          placeholder="Email"
                          value={UserDetail.EmailID}
                          onChange={(e) =>
                            setUserDetail({
                              ...UserDetail,
                              ["EmailID"]: e.target.value,
                            })
                          }
                          isInvalid={UserDetail.errors && UserDetail.errors.EmailID}
                        />
                        {UserDetail.errors && UserDetail.errors.EmailID && (
                          <Form.Control.Feedback type="invalid">
                            {UserDetail.errors.EmailID}
                          </Form.Control.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col xs={6} className="mb-3">
                      <Row>
                        <Col xs={3}>
                          <FloatingLabel controlId="floatingPassword" label="Code">
                            <Form.Control
                              type="text"
                              maxLength={6}
                              placeholder="Country Code"
                              value={UserDetail.CountryCode}
                              onChange={(e) =>
                                setUserDetail({
                                  ...UserDetail,
                                  ["CountryCode"]: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </Col>

                        <Col xs={6} className="mb-3">
                          <FloatingLabel controlId="floatingInput" label="">
                            <div
                              className={`${
                                UserDetail.errors.ContactNo &&
                                UserDetail.errors.ContactNo &&
                                "red-border-masking"
                              }`}
                            >
                              <FloatingLabel controlId="floatingInput" label="Contact No.">
                                <PatternFormat
                                  className="form-control"
                                  placeholder="Contact No."
                                  displayType="input"
                                  format="###-###-####"
                                  value={UserDetail.ContactNo && UserDetail.ContactNo}
                                  onChange={(e) =>
                                    setUserDetail({
                                      ...UserDetail,
                                      ["ContactNo"]: e.target.value,
                                    })
                                  }
                                />
                              </FloatingLabel>
                            </div>
                            {UserDetail.errors.ContactNo && (
                              <>
                                <span className="feedbackss">{UserDetail.errors.ContactNo}</span>
                                <br />
                              </>
                            )}
                          </FloatingLabel>
                        </Col>
                        <Col xs={3}>
                          <FloatingLabel controlId="floatingPassword" label="Ext">
                            <Form.Control
                              type="text"
                              placeholder="Ext"
                              value={UserDetail.Ext}
                              onChange={(e) =>
                                setUserDetail({
                                  ...UserDetail,
                                  ["Ext"]: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
            
                  <Row className="mb-2">
                    <Col xs={12} className="mb-3">
                      <h6 className="mb-3">Owner(Role)</h6>
                      <div className={`${IsStatusSelect.status === true && "red-border-masking"}`}>
                        {["checkbox"].map((type: any) => (
                          <div key={`inline-${type}`} className="option-field">
                            <Row>
                              {UserRole.length > 0 &&
                                UserRole.map((item: any) => (
                                  <>
                                    <Col sm={6} lg={4} xl={3}>
                                      <Form.Check
                                        inline
                                        label={item.RoleName}
                                        value={item.RoleID}
                                        defaultChecked={item.IsSelected}
                                        name="group1"
                                        type={type}
                                        onChange={(e) => SelectedRoleValues(e, item)}
                                        id={item.RoleName}
                                      />
                                    </Col>
                                  </>
                                ))}
                            </Row>
                          </div>
                        ))}
                        <small style={{ color: "#dc3545" }}>
                          {IsStatusSelect.status === true && IsStatusSelect.message}
                        </small>
                      </div>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <h6 className="mb-3">User Portfolio</h6>
                      {["checkbox"].map((type: any) => (
                        <div key={`inline-${type}`} className="option-field">
                          <Row>
                            {UserPortfollio.length > 0 &&
                              UserPortfollio.map((item) => (
                                <>
                                  <Col sm={6} lg={4} xl={3}>
                                    <Form.Check
                                      inline
                                      label={item.PortfolioName}
                                      value={item.PortfolioID}
                                      // defaultChecked={ }
                                      defaultChecked={item.IsSelected ? true : false}
                                      name="group1"
                                      type={type}
                                      onChange={(e) => SelectedPortfolioValues(e, item)}
                                      id={item.PortfolioName}
                                    />
                                  </Col>
                                </>
                              ))}
                          </Row>
                        </div>
                      ))}
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col xs={12} className="mb-3">
                      <div className="select-logo-title">User Property Selection</div>
                      <Col xs={12}>
                        <div className="d-flex w-100">
                          <div className="w-100">
                            <div
                              className={`${
                                UserDetail.errors.property.length && "red-border-masking"
                              }`}
                            >
                              <FloatingLabel controlId="floatingInput" label="">
                                <Select
                                  value={PortfolioHotels}
                                  options={MyOption}
                                  onChange={SelectedHotel}
                                  isMulti
                                  name="colors"
                                  placeholder="Select Hotel"
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                />
                              </FloatingLabel>
                            </div>
                            <small style={{ color: "#dc3545" }}>
                              {" "}
                              {UserDetail.errors.property && UserDetail.errors.property}{" "}
                            </small>
                          </div>
                          <div className="user-property-selection-setting">
                            <Button
                              type="button"
                              onClick={() => {
                                if (PortfolioHotels.length) {
                                  setShowProdGoalsModal(true);
                                  // setProdInputs
                                  if (!Object.keys(prodInputs).length) {
                                    let tempProdData: any = {};
                                    let tempProdDataEr = {};

                                    PortfolioHotels.forEach((htl_itm: any) => {
                                      tempProdData = {
                                        ...tempProdData,
                                        [htl_itm.value]: {
                                          propertyID: htl_itm.value,
                                          hotelName: htl_itm.label,
                                          roleID: "",
                                          clientVisit: 0,
                                          siteVisit: 0,
                                          blitz: 0,
                                          telemarketing: 0,
                                          networking: 0,
                                        },
                                      };
                                      tempProdDataEr = {
                                        ...tempProdDataEr,
                                        [htl_itm.value]: {
                                          propertyID: "",
                                          roleID: "",
                                        },
                                      };
                                    });
                                    setProdInputsEr(tempProdDataEr);
                                    setProdInputs(tempProdData);
                                  }
                                } else {
                                  ToasterError("Please select atleast one hotel.");
                                }
                              }}
                              variant="secondary"
                            >
                              <img src={UserSettingIcon} alt="" />
                            </Button>
                          </div>
                        </div>
                      </Col>
                      {/* <Col xs={6} className="mb-3">
                          <div className="user-property-selection-setting">
                            <Button
                              type="button"
                              onClick={() => {
                                if (PortfolioHotels.length) {
                                  setShowProdGoalsModal(true);
                                  // setProdInputs
                                  if (!Object.keys(prodInputs).length) {
                                    let tempProdData: any = {};
                                    let tempProdDataEr = {};

                                    PortfolioHotels.forEach((htl_itm: any) => {
                                      tempProdData = {
                                        ...tempProdData,
                                        [htl_itm.value]: {
                                          propertyID: htl_itm.value,
                                          hotelName: htl_itm.label,
                                          roleID: "",
                                          clientVisit: 0,
                                          siteVisit: 0,
                                          blitz: 0,
                                          telemarketing: 0,
                                          networking: 0,
                                        },
                                      };
                                      tempProdDataEr = {
                                        ...tempProdDataEr,
                                        [htl_itm.value]: {
                                          propertyID: "",
                                          roleID: "",
                                        },
                                      };
                                    });
                                    setProdInputsEr(tempProdDataEr);
                                    setProdInputs(tempProdData);
                                  }
                                } else {
                                  toast.error("Please select atleast one hotel.");
                                }
                              }}
                              variant="secondary"
                            >
                              <img src={UserSettingIcon} alt="" />
                            </Button>
                          </div>
                        </Col> */}
                    </Col>

                    {/* <Col xs={6} className="mb-3 pe-0">
                    <div className="select-logo-title">User Logo</div>
                    <div className="custom-file comaninputfile">
                      {LogoUpload && (
                        <img
                          src={LogoUpload}
                          style={{
                            display: "flex",
                            border: "2px solid tomato",
                            maxWidth: "300px",
                            maxHeight: "300px",
                          }}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="comninputbtn">
                      <span>Select Logo</span>
                      <input
                        type="file"
                        accept="image/*"
                        id="inputGroupFile01"
                        className=""
                        onChange={SelectLogo}
                      />
                    </div>
                  </Col> */}
                  </Row>
                  <Row className="mb-2">
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="Notes">
                        <Form.Control
                          as="textarea"
                          style={{ height: "100px" }}
                          placeholder="Notes"
                          value={UserDetail.Notes}
                          onChange={(e) =>
                            setUserDetail({
                              ...UserDetail,
                              ["Notes"]: e.target.value,
                            })
                          }
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={6}>
                      {["checkbox"].map((type: any) => (
                        <div key={`inline-${type}`} className="option-field">
                          <Form.Check
                            inline
                            label="Email Welcome Message"
                            name="group1"
                            type={type}
                            id="Email Welcome Message"
                            // defaultChecked
                            checked={UserDetail.IsSendMail}
                            onChange={(e) => {
                              setUserDetail({
                                ...UserDetail,
                                ["IsSendMail"]: e.target.checked,
                              });
                            }}
                          />
                        </div>
                      ))}
                    </Col>
                  </Row>
                </>
              ) : (
                "LOADING... "
              )}

              <ProdGoals
                showProdGoalsModal={showProdGoalsModal}
                setShowProdGoalsModal={setShowProdGoalsModal}
                prodInputs={prodInputs}
                setProdInputs={setProdInputs}
                prodInputsEr={prodInputsEr}
                setProdInputsEr={setProdInputsEr}
              />
            </>
          ) : (
            <PageLoader />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={SaveUser}
            disabled={OnProcessHide === true ? true : false}
          >
            <span className="me-2">
              <img src={WhiteSaveIcon} alt="" />
            </span>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {hotelChangeLoading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default UserModal;
